.wrapBtn {
    > button:first-of-type {
        min-width: 40px;
        border-radius: 20px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
            height: 30px;
            width: 30px;
            color: white;
        }
    }
}
