.label {
    margin-bottom: 8px;
    font-weight: bold;
}
.imageUploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    border: 1px solid #bbb;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;

    .inputFile {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        z-index: 10;
    }

    .preview {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        text-align: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        svg {
            fill: var(--G7);
            height: 100px;
            border: 3px solid var(--G7);
            border-radius: 50%;
            transition: transform 0.3s ease;
        }

        p {
            font-size: 14px;
            color: #666;
        }
    }

    &:hover svg {
        transform: scale(1.1);
    }
}
