.wrapVideoEditor {
    border-bottom: 2px dashed grey;

    & > div {
        display: flex;
        flex: 1 1;
        gap: 20px;
        margin-bottom: 10px;

        & > div,
        iframe {
            width: 100%;
            height: 100%;
            flex: 1 1;
        }

        & > iframe {
            height: 25vw;
        }

        &:last-of-type {
            display: flex;
            justify-content: flex-end;
        }

        .wrapVideoChange {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            align-items: flex-end;

            .generateLink {
                margin-top: 10px;
            }

            > div {
                width: 100%;

                & > p {
                    font-size: 12px;
                }
            }

            .error {
                color: tomato;
            }
        }
    }
}
