.wrapSchedule {
    width: 100%;
    padding: 0 20px;

    h5 {
        margin-bottom: 0;
    }

    .scheduleChildren {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: 500px) {
            font-size: 20px;
        }

        li {
            margin-bottom: 9px;
        }

        ul > strong {
            display: inline-block;
            color: red;
            margin-bottom: 10px;
        }
    }
}
