.wrapAdminAboutUs {
    & > div {
        display: flex;
        flex-direction: column;
    }

    & > div > div:last-of-type {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    div[class*="imageUploader"] {
        height: 350px;
        width: 600px;
        margin: 0;
    }

    & > div:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 2px dashed grey;
    }
}
