.app {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    .header{
        position: fixed;
        width: 100%;
        z-index: 1000;
    }

    .main {
        flex: 1;
        margin-top: 80px;
    }
}
