.wrapMedia {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    iframe {
        width: 100%;
        height: 430px;
    }
}

.modalImage {
    img {
        width: 90%;
    }
}
