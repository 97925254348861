@import "./normalize.css";
@import "../fonts/fonts.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #495057;
    --gray-darker: #343a40;
    --primary: #0083cd;
    --secondary: #c4c9ce;
    --success: #00a65a;
    --info: #95efff;
    --warning: #ffdf11;
    --danger: #e44655;
    --light: #eef0f2;
    --dark: #212121;
    --black: #000000;

    --hover_link: #14b9ff;

    /* grey scale*/
    --G1: #000000;
    --G2: #282a2c;
    --G3: #5a5c5e;
    --G4: #646668;
    --G5: #96989a; /* + */
    --G6: #afb1b3;
    --G7: #c8cacc;
    --G8: #d7d9db;
    --G9: #e1e3e5;
    --G10: #e6e8ea;
    --G11: #ebedef;
    --G12: #f0f2f4;
    --G13: #f5f7f9;
    --G14: #ffffff;
}

html {
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden;
    background-color: var(--G12);
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    transition: color 150ms ease-out, background-color 150ms ease-out;
    height: 100%;
    overflow: hidden;
    color: #000;
}

header {
    height: 80px;
}

#root {
    height: 100%;
}

img {
    display: block;
}

button {
    cursor: pointer;
}

/* scrollbar Thumb  */
/* style for scrollbar-track */
.scrollbarThumb::-webkit-scrollbar-track {
    background-color: transparent;
    padding-right: 0.25rem;
    margin-left: 10px;
}

.scrollbarThumb::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: rgba(211, 208, 208, 0.47);
}

.scrollbarThumb::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 50px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.inner_html {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        line-height: 1.3;
    }

    ul,
    ol {
        margin: 0;
        margin-top: 7px;
    }
}
