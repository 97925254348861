.wrapAboutUs {
    padding: 0 0 20px;

    @media screen and (max-width: 1220px) {
        padding: 0 20px 5px;
    }

    & > h2 {
        margin-bottom: 10px;
        font-size: 22px;
    }

    & div:last-of-type {
        flex-direction: row-reverse;
    }

    .wrapInfo {
        display: flex;
        gap: 20px;

        &:first-of-type {
            margin-bottom: 10px;
        }

        .wrapImg {
            display: flex;
            width: 50%;
            display: block;
            height: auto;
            max-height: 70vh;

            @media (min-width: 1200px) {
                max-height: 38vh;
            }

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .wrapDescr {
            width: 50%;

            & > h3 {
                margin: 0 0 10px;
            }

            & > p {
                margin-bottom: 10px;
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 768px) {
    .wrapAboutUs {
        & > div:last-of-type {
            flex-direction: column;
        }

        .wrapInfo {
            flex-direction: column;

            .wrapImg {
                height: 50vh !important;
            }

            .wrapImg,
            .wrapDescr {
                width: 100% !important;
            }
        }
    }
}
