.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    padding: 10px;
    height: 40px;

    .media {
        display: flex;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        height: 120px;
        padding: 5px;

        & a {
            margin-bottom: 5px;
        }
    }
    span {
        color: yellow;
    }
    a {
        display: flex;
        color: yellow;

        &:hover,
        &:focus,
        &:active {
            color: var(--hover_link);

            & svg {
                fill: var(--hover_link);
            }
        }

        & svg {
            margin-left: 5px;
            fill: yellow;
            width: 20px;
            height: 20px;
        }
    }
}
