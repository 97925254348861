.wrapHeader {
    border-bottom: 1px dashed var(--hover_link);
    background-color: #000;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.35);
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .wrapLogo {
        margin-top: 5px;
    }

    .menu {
        display: flex;
        gap: 20px;
        white-space: nowrap;

        a.isActive {
            color: var(--hover_link) !important;
        }

        a {
            color: yellow;
            text-transform: uppercase;
            text-decoration: none;

            &:hover,
            &:focus {
                color: var(--hover_link);
            }
        }
    }

    svg {
        color: #fff;
    }
}

.mobile_btn {
    display: none;
}

@media (max-width: 1000px) {
    .mobile_btn {
        display: block;
        position: absolute;
        right: 10px;
        top: 27px;
        cursor: pointer;
        z-index: 10;
    }

    .menu {
        padding: 20px;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: -100%;
        right: 0;
        top: 80px;
        bottom: 0;
        width: 100%;
        height: calc(100vh - 80px);
        background-color: #000;
        z-index: 10;
        transition: left 1s;

        a {
            font-size: 28px;
        }
    }

    .active {
        left: 0;
    }
}
