.wrapGalleryEditor {
    width: 100%;

    .galleryList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 0;
        margin: 0;

        .wrapImageUploader > div {
            height: 100%;
            min-height: 285px;
        }

        .wrapOneImage {
            position: relative;

            .wrapBtns {
                position: absolute;
                display: flex;
                height: 100%;
                width: 100%;
                top: 0;
                align-items: flex-end;
                justify-content: space-between;
                padding: 10px;
            }
        }

        & li {
            width: 100%;
            list-style: none;
            border-radius: 10px;
            overflow: hidden;
        }

        & li img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }
}
