.wrapTreatment {
    .treatmentsList {
        display: grid;
        grid-template-columns: 3fr;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-left: 0;
        padding-left: 0;

        & > li {
            list-style-type: none;
        }

        .treatment:hover {
            opacity: 0.7;
        }
        .linkCard {
            text-decoration: none;
            color: #000;
            padding-bottom: 10px;

            .treatmentImg {
                width: 100%;
                height: 280px;
                object-fit: cover;
            }

            .description {
                text-decoration: underline;
                line-height: 1.5;
                margin-bottom: 3px;
            }

            & > span {
                color: var(--orange);
                text-decoration: underline;
                opacity: 0.5;
            }
        }
    }

    .descr {
        background-color: white;
        border-radius: 6px;
        padding: 10px 20px;
        margin-bottom: 10px;

        & > p {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 1250px) {
    .wrapTreatment {
        padding: 0 20px;

        .treatmentsList {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media screen and (max-width: 1000px) {
    .wrapTreatment {
        .treatmentsList {
            grid-template-columns: repeat(2, 1fr);
            .linkCard {
                .treatmentImg {
                    height: 400px !important;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .wrapTreatment {
        .treatmentsList {
            grid-template-columns: 1fr;
            .linkCard {
                .treatmentImg {
                    height: 250px !important;
                }
            }
        }
    }
}

.oneTreatment {
    padding: 20px 0;
    width: 100%;

    @media screen and (max-width: 1220px) {
        padding: 10px;
    }

    & > p {
        margin-bottom: 20px;
    }

    .wrapImg {
        float: right;
        width: 340px;
        height: 340px;
        margin-left: 10px;

        & > img {
            width: 100%;
            height: 100%;
        }
    }
}
