.link {
    li {
        border-bottom: 1px dashed grey;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-left: 25px;

        & > div > img {
            width: 150px;
            height: 150px;
            object-fit: fill;
            align-self: center;
        }

        & > div:first-of-type {
            margin-right: 10px;

            & > p:first-of-type {
                font-weight: bold;
                margin-bottom: 10px;
            }
            & > p:last-of-type {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
            }
        }

        .wrapRight {
            position: relative;

            > span {
                position: absolute;
                bottom: 10px;
                left: 10px;
            }
        }
    }
}
