.wrapTextEditor {
    margin-bottom: 10px;

    div[class*="ql-toolbar"] {
        border-radius: 5px 5px 0 0;
        background: white;
    }
    div[class*="ql-container"] {
        border-radius: 0 0 5px 5px;
        background: white;
    }

    label {
        line-height: 2.3;
        font-weight: bold;
    }
}
