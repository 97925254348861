.wrapAdminLeftNavbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nav {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 200px;
        padding: 0 10px 0 0;

        a {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: #333;
            padding: 10px;
            border-radius: 4px;
            text-decoration: none;

            &:hover {
                background-color: var(--G8);
            }

            &.active {
                background-color: #ccc;
            }
        }

        a.isActive {
            background-color: var(--G8) !important;
        }
    }

    & > h4 {
        padding: 10px;
        margin: 0;
    }
    & > button {
        min-width: 190px;
    }
}
