.wrapSlider {
    max-width: 1200px;
    margin: 0 auto;

    .wrapImage {
        height: 400px;
        width: 33.33%;
        cursor: grab;

        & > img {
            padding: 0 10px;
            width: 100%;
            height: inherit;
            object-fit: cover;
        }
    }
}
