/* caveat-regular - cyrillic */
@font-face {
    font-family: "Caveat";
    font-style: normal;
    font-weight: 400;
    src: url("./caveat-v7-cyrillic/caveat-v7-cyrillic-regular.eot");
    src: local("Caveat Regular"), local("Caveat-Regular"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-regular.eot?#iefix")
            format("embedded-opentype"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-regular.woff2")
            format("woff2"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-regular.woff")
            format("woff"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-regular.ttf")
            format("truetype"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-regular.svg#Caveat")
            format("svg");
}
/* caveat-700 - cyrillic */
@font-face {
    font-family: "Caveat";
    font-style: normal;
    font-weight: 700;
    src: url("./caveat-v7-cyrillic/caveat-v7-cyrillic-700.eot");
    src: local("Caveat Bold"), local("Caveat-Bold"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-700.eot?#iefix")
            format("embedded-opentype"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-700.woff2") format("woff2"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-700.woff") format("woff"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-700.ttf")
            format("truetype"),
        url("./caveat-v7-cyrillic/caveat-v7-cyrillic-700.svg#Caveat")
            format("svg");
}

/* ================== */
/* comfortaa-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 300;
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-300.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./comfortaa-v40-latin/comfortaa-v40-latin-300.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-300.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-300.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 400;
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-regular.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-regular.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 500;
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-500.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./comfortaa-v40-latin/comfortaa-v40-latin-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-500.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-500.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 600;
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-600.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./comfortaa-v40-latin/comfortaa-v40-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-600.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-600.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 700;
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-700.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-latin/comfortaa-v40-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./comfortaa-v40-latin/comfortaa-v40-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-700.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-latin/comfortaa-v40-latin-700.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}

/* ========================================== */
/* comfortaa-300 - cyrillic */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 300;
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-300.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-300.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-300.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-300.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-regular - cyrillic */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 400;
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-regular.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-regular.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-500 - cyrillic */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 500;
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-500.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-500.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-500.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-600 - cyrillic */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 600;
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-600.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-600.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-600.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
/* comfortaa-700 - cyrillic */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 700;
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-700.eot"); /* IE9 Compat Modes */
    src: url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-700.woff")
            format("woff"),
        /* Modern Browsers */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("./comfortaa-v40-cyrillic/comfortaa-v40-cyrillic-700.svg#Comfortaa")
            format("svg"); /* Legacy iOS */
}
