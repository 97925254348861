.wrapAddItem {
    border: 1px solid #bbb;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 24px;

    svg {
        fill: var(--G7);
        height: 100px;
        border: 3px solid var(--G7);
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    &:hover svg {
        transform: scale(1.1);
    }
}
