.wrapTextarea {
    & > textarea {
        width: 100%;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid rgb(204, 204, 204);
        font-size: 16px;
        overflow: hidden;
        margin-bottom: 10px;
    }

    & > label {
        font-weight: bold;
        line-height: 2;
    }
}
