.wrapContacts {
    @media screen and (max-width: 800px) {
        padding: 0;
    }

    .wrapLocation {
        display: flex;
        justify-content: space-around;

        & > div {
            flex: 1;

            p {
                line-height: 1.7;
                margin: 0;
            }
        }

        @media screen and (max-width: 720px) {
            flex-direction: column;
            padding: 0 10px 10px;
        }
    }

    .wrapMap {
        & > img {
            width: 100%;
            object-fit: contain;
        }
    }
}
