.wrapRecreation {
    width: 100%;
    @media screen and (max-width: 1220px) {
        padding: 0 20px;
    }

    .conditions {
        padding-left: 40px;
        margin-bottom: 20px;
        li {
            list-style-type: disc;
        }
    }
}
