.wrapAdminNews {
    & > a {
        border-bottom: 1px dashed grey;
        height: 160px;
        display: block;
        margin-bottom: 10px;

        & > div {
            height: 150px;
        }
    }
}

.wrapNewsList {
    margin: 0;
    padding: 0;

    & > a:not(:last-of-type) > li {
        margin-bottom: 10px;
    }
}

.wrapAdminOneNews {
    & > div:last-of-type {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    div[class*="imageUploader"] {
        height: 400px;
        width: 1000px;
        margin: 0;
    }
}
