.btn {
    padding: 10px 20px;
    height: 40px;
    font-weight: 700;
    background: var(--hover_link);
    color: yellow;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;

    & > svg {
        width: 18px;
        fill: yellow;
    }

    &:hover {
        background: yellow;
        color: var(--hover_link);

        svg {
            fill: var(--hover_link);
        }
    }
}
