.wrapAdmin {
    display: flex;
    min-height: 100%;
    padding: 10px;

    & > div:nth-child(2) {
        flex: auto 1;

        // & > button [class*="green"] {
        //     align-self: center;
        //     align-items: center;
        //     display: flex;
        //     justify-content: center;
        // }
    }
}
