.wrapRoot {
    height: 100%;

    .bg {
        background-image: url("../../assets//images/root.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: calc(100% - 40px);
        position: fixed;
        z-index: -1;

        @media (max-width: 768px) {
            height: calc(100% - 182px);
        }
    }

    & > p {
        font-size: 55px;
        font-weight: 400;
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Comfortaa;

        & > span {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}
