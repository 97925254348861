.wrapNews {
    width: 100%;
    margin-bottom: 20px;

    > ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 20px;
        margin-left: 0;
        padding-left: 0;

        > li {
            list-style-type: none;
            width: 590px;

            & > a {
                text-decoration: none;
                color: #000;

                & > p {
                    text-decoration: underline;
                    line-height: 1.5;
                    margin-bottom: 3px;
                }
                & > span {
                    color: var(--orange);
                    text-decoration: underline;
                    opacity: 0.5;
                }

                &:hover {
                    opacity: 0.7;
                }
            }

            > a img {
                width: 100%;
                object-fit: fill;
                cursor: pointer;
                height: 350px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .wrapNews {
        gap: 10px;
        padding: 0 20px;

        > ul li {
            width: 100%;

            > a img {
                height: 260px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .wrapNews {
        gap: 10px;
        padding: 0 20px;

        & > ul {
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;

            > li a img {
                height: 270px;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .wrapNews {
        & > ul li a img {
            height: 180px;
        }
    }
}

// OneNews
.wrapOneNews {
    padding: 20px 0;

    @media screen and (max-width: 1220px) {
        padding: 10px;
    }

    .wrapImg {
        width: 100%;
        height: 600px;

        @media screen and (max-width: 1000px) {
            height: 400px;
        }
        @media screen and (max-width: 800px) {
            height: 300px;
        }
        @media screen and (max-width: 600px) {
            height: 200px;
        }
        @media screen and (max-width: 400px) {
            height: 150px;
        }

        & > img {
            height: 100%;
            width: 100%;
        }
    }
}
