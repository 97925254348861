.btn {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: fit-content;
    min-width: 200px;
    height: 40px;
}

.green {
    &:hover,
    &:focus {
        background-color: #3e8e41;
    }
}
.red {
    background: tomato;

    &:hover,
    &:focus {
        background: #e66c56;
    }
}
.grey {
    background: var(--G8);

    &:hover,
    &:focus {
        background: var(--G7);
    }
}
